<template>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>ATK Keluar</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                        <li class="breadcrumb-item"><router-link to="/tran_atk_out">Atk Keluar</router-link></li>
                        <li class="breadcrumb-item active">Form</li>
                    </ol>
                </div>
                </div>
            </div>  
        </section>                    
        <section class="content">
                    <div class="row">
                        <!-- left column -->
                        <div class="col-md-12">
                            <slot name="notification"></slot>
                            <!-- general form elements -->
                            <div class="card card-primary">
                                <div class="card-header">
                                    <h3 class="card-title">ATK Keluar</h3>
                                </div>

                                <form role="form" @submit="e => { e.preventDefault(); onSubmit(fields); }">
                                    <div class="card-body">
                                       <!-- <div class="card"> -->
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label for="notrans">No Transaksi </label>
                                                <input class="form-control" type="text" id="notrans" :disabled="fields.notrans || update" v-model="fields.notrans"   placeholder="No Transaksi" />
                                                
                                            </div>
                                            <div class="form-group col-md-6">

                                                <label for="notrans">Tanggal Transaksi </label>
                                                <div class="input-group-append">
                                                <datepicker v-model="fields.tgl" id="tgltrans" :disabled="update"></datepicker>
                                                <!-- <datepicker v-model="fields.tgl" id="tgltrans" ></datepicker> -->
                                                <button v-if="!update" :disabled="update" type="button" @click="genLastNo()" class="btn btn-info genNo">No </button>
                                                </div>
                                                <!-- <a  class="btn btn-block btn-info" @click="genLastNo()">Gen</a> -->
                                                
                                                
                                            
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="Jenis">Tipe <span class="text-danger">(*)</span></label>
                                                <!-- <v-select v-if="!isLoading" :options="options" :reduce="country => country.id" label="name" v-model="fields.ms_supplier" /> -->
                                                <!-- <v-select :options="['BELI', 'MUTASI', 'GRATIS']"  v-model="fields.tipe"></v-select> -->
                                                <!-- <v-select :options="[ { id:1, name:'BELI'}, {id:2, name:'MUTASI'}, {id:3, name:'GRATIS'}]" label="name" v-model="fields.tipe"></v-select> 
                                                { id:0, name:''},
                                                -->
                                                <v-select 
                                                v-model="fields.tipe" 
                                                :options="[  { id:1, name:'BELI'}, {id:2, name:'MUTASI'}, {id:3, name:'GRATIS'}]" :reduce="(label) => label.id" label="name" ></v-select>
                                                    <!-- <p>data yang dipilih adalah {{ fields.tipe }}</p> 
                                                    { id:0, name:''}, 
                                                    -->
                                                    
                                                <!-- <dropdown
                                                    :onChange="changeSupplier"
                                                    :url="`/supplier`"
                                                    id="id"
                                                    label="name"
                                                    :required="true"
                                                    :updated="update"
                                                    :disabled="update"
                                                    :clearable="false"
                                                    placeholder="Select Supplier"
                                                /> -->
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="ket">Keterangan </label>
                                                 
                                                <textarea class="form-control" id="ket" v-model="fields.ket" placeholder="Keterangan" rows="3" />
                                            </div>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 2%">No.</th>
                                                        <th style="width: 10%">Gudang</th>
                                                        <th style="width: 20%">Item</th>
                                                        <th style="width: 2%">Qty</th>
                                                    
                                                        <!-- <th style="width: 40px">Action</th> -->
                                                        <th style="width: 2%">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                    <tr v-for="(item, index) in fields.detail" :key="index">
                                                        <td><!-- {{ index+1 }} --><input type="text" class="form-control" disabled="true" v-model="item.Keluar_d_no" /></td>
                                                        <td>
                                                              <!--  <b-button variant="success" v-b-modal.modal-1>..</b-button> -->
                                                              <b-input-group>
                                                              <input type="text" class="form-control" disabled="true" v-model="item.gdg_code" />
                                                              <b-button variant="success" @click="showGdg(index)">...</b-button>
                                                              </b-input-group>
                                                              <b-modal :ref="'listGdg'+index" :id="'modal'+index" title="Pencarian Gudang" size="xl">
                                                                {{ baris }}
                                                                    <!-- <p class="my-4">Content goes here...</p> -->
                                                                    <div>
                                                                          <!--   <template v-slot:cell(actions)="{ item }">
                                                                                <span><b-btn @click="editItem(item)">Edit</b-btn></span>
                                                                            </template> -->
                                                                        <b-table hover :items="gdgList" :fields="gdgFields">
                                                                           <!--  <template v-slot:cell(actions)="{ data }">
                                                                                <b-btn @click="editItem(data)">Edit</b-btn>
                                                                                , item.index
                                                                            </template> -->
                                                                            <template v-slot:cell(actions)="data">
                                                                                <b-button variant="success" @click="clickGdg(data.item,  baris )">Pilih</b-button>
                                                                            </template>
                                                                        </b-table>

                                                                         
                                                                    </div>
                                                              </b-modal>
                                                            <!-- <dropdown
                                                                :onChange="changeProduct"
                                                                :url="apiUrl+`gudangs`"
                                                                
                                                                :except="allProductId"
                                                                :id="'ms_product_id['+index+']'"
                                                                label="name"
                                                                :required="true"
                                                                :updated="update"
                                                                :disabled="update"
                                                                :clearable="false"
                                                                :customData="[index]"
                                                                placeholder="Select product"
                                                            /> -->
                                                        </td>
                                                        <td>
                                                            <b-input-group>
                                                              <input type="text" class="form-control" disabled="true" v-model="item.item_code" />
                                                              <b-button variant="success" @click="showItem(index)">...</b-button>
                                                              </b-input-group>
                                                              <b-modal :ref="'listItem'+index" :id="'modal'+index" title="Pencarian Item" size="xl">
                                                                {{ baris }}
                                                                    <div>
                                                                        <b-table hover :items="itemList" :fields="itemFields">
                                                                            <template v-slot:cell(actions)="data">
                                                                                <b-button variant="success" @click="clickItem(data.item,  baris )">Pilih</b-button>
                                                                            </template>
                                                                        </b-table>

                                                                         
                                                                    </div>
                                                              </b-modal>
                                                        </td>
                                                        <td>
                                                            
                                                            <input type="number" class="form-control" v-model.number="item.Keluar_d_qty" min="0" placeholder="Qty">
                                                        </td>
                                                        
                                                        <td>
                                                            <a v-if="!update" class="btn btn-block btn-danger" @click="remove(index)">Delete</a>
                                                        </td>
                                                    </tr>
                                                    <tr class="no-border">
                                                        <td> 
                                                            <a v-if="!update" class="btn btn-block btn-info" @click="add()">Add</a>
                                                        </td>
                                                        
                                                        <td> Total </td>
                                                        <td>
                                                            <input :value="total" class="form-control text-right" disabled/>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                       <!-- </div> -->
                                    </div>

                                    <div class="card-footer">
                                        <button type="submit" class="btn btn-primary">Submit</button>
                                        <!-- <a @click="$router.go(-1)" class="btn btn-danger">Back</a> -->
                                        <a @click="$router.push({name: 'tran_atk_out'})" class="btn btn-danger">Back</a>
                                    </div>
                                </form>
                            </div>
                            <!-- /.box -->

                        </div>
                        
                    </div>
                <!-- /.row -->
        </section>
    </div>   
</template>


<script>
    //import axios from 'axios'
    import Datepicker from 'vuejs-datepicker'
    //import Dropdown2 from '../../../../Components/Dropdown2';
    //import Dropdown2 from '../../../../components/Dropdown2.vue';
    //import PopupCustom from '../../../../components/PopupCustom.vue';
    import { defaultFieldItems } from "./helpers";
    export default {
        components: {
             Datepicker,
      //       'dropdown': Dropdown2,
             //'popup' :PopupCustom
            },
     
        props: {
                initialFields: {
                    type: Object,
                    required: true,
                },
                onSubmit: {
                    type: Function,
                    required: true,
                },
                update: {
                    type: Boolean,
                    default: false
                }
            },
        data() {
            return {
                items: [],
                baris: null,
                gdgList: [],
                itemList: [],
                fields: {},
                //gdgFields: [],
                gdgFields: [{ key: 'gdg_code', label: 'GDG' }, 'last_name', 'age', 'actions'],
                itemFields: [{ key: 'item_code', label: 'BRG' }, { key: 'item_name', label: 'NAMA' }, { key: 'item_desc', label:        'KET' }, 'actions'],
                
                tes: [
                        { isActive: true, age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
                        { isActive: false, age: 21, first_name: 'Larsen', last_name: 'Shaw' },
                        { isActive: false, age: 89, first_name: 'Geneva', last_name: 'Wilson' },
                        { isActive: true, age: 38, first_name: 'Jami', last_name: 'Carney' }
                        ],
                total: 0,
                //apiUrl: this.apiUrl,
                name : this.$route.name,
                menu : this.$route.meta.menu,
                path : this.$route.path,
                sidebar : this.$router.options.routes,
                path1 : this.$router.currentRoute.path,
                /* fields: defaultFields(), */
            options: [],
            options1: [{ id:1, name:'BELI'}, {id:2, name:'MUTASI'}, {id:3, name:'GRATIS'}],
            isLoading: false,
            }
        },     
        beforeMount() {
            this.fields = { ...this.initialFields};      
            console.log('before mount');
             console.log(this.fields);
             
             //console.log(this.urlApi);
            if (!this.update) this.getLastNumber();   
            //console.log('recount detail');
            this.recountDetail();
            /* var today = new Date();
	        var test =  this.vs.formatInputDate(today);
            console.log(test); */
        },
        
        
         methods: {
            add() {
                console.log('add')
            this.fields.detail.push(defaultFieldItems());
            this.recountDetail();
            /* var newFields = {...this.fields}
                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    //element.t_memo_account_id = element.id
                    element.gdg_id = 0
                    element.item_id = 0
                    element.qty = 0
                    element.price = 0
                     
                    newFields.detail.push(element)
                    this.errors.detail.push({})
                }
            this.fields = newFields */
            },
            recountDetail(){
                var newFields = {...this.fields}
                var idx = 0;
                //newFields.detail.forEach(element => {         
                //console.log(this.fields.detail);
                //console.log('inside recount detail');
                this.fields.detail.forEach(element => {             
                    console.log(element.gdg_code);
                    newFields.detail[idx].Keluar_d_no = ++idx
                });
                console.log(newFields);
                this.fields = newFields;
            },
            remove(index) {
            var newFields = { ...this.fields };
            newFields.detail = this.fields.detail.filter((value, i) => {
                return i != index;
            });            
            this.fields = newFields;
            this.recountDetail();
        },
            genLastNo() {
                this.getLastNumber();   
            },
            clickGdg(data=[], index = 0) {
              /*   console.log(data);
                console.log(index);                
                console.log(this.baris);   */              
                var newFields = { ...this.fields };
                //console.log(newFields);
                /* newFields.detail[index].gdg_id = data?.id;
                newFields.detail[index].gdg_code = data?.gdg_code; */
                newFields.detail[index].gdg_id = data?.id;
                newFields.detail[index].gdg_code = data?.gdg_code;
                /* newFields.detail[index].m_account_code = data?.m_account_code;
                newFields.detail[index].m_account_desc = data?.m_account_desc;
                newFields.detail[index].t_memo_account_code = data?.m_account_code; */
                //console.log(newFields);
                this.fields = newFields;
                /* console.log(this.fields); */
                this.baris=index;
                //this.$refs.listGdg[this.baris].hide();
                //this.$refs['listGdg'+index].hide();
                this.$refs['listGdg'+index][0].hide();
                //console.log(this.$refs.listGdg[this.baris]);
                console.log(this.$refs['listGdg'+index]);
            },
            clickItem(data=[], index = 0) {
                var newFields = { ...this.fields };
                newFields.detail[index].item_id = data?.id;
                newFields.detail[index].item_code = data?.item_code;
                this.fields = newFields;
                this.baris=index;
                this.$refs['listItem'+index][0].hide();
                console.log(this.$refs['listItem'+index]);
            },
            accDesc(data=[], index = 0){
                console.log(data?.m_account_desc)
                var newFields = { ...this.fields };
                newFields.detail[index].t_memo_account_id = data?.id;
                newFields.detail[index].m_account_code = data?.m_account_code;
                newFields.detail[index].m_account_desc = data?.m_account_desc;
                newFields.detail[index].t_memo_account_code = data?.m_account_code;
                this.fields = newFields;
            },
            changeSupplier(value) {
                var newFields = { ...this.fields };
                newFields.ms_supplier_id = value;
                this.fields = newFields;
            },
            changetipe(value) {
                var newFields = { ...this.fields };
                console(value);
                newFields.tipe = value;
                this.fields = newFields;
            },
            changeProduct(value, index) {
                var newFields = { ...this.fields };
                newFields.detail[index].ms_product_id = value.id;
                newFields.detail[index].price = value.price;
                this.fields = newFields;
            },
                
                async getLastNumber() {
                this.isLoading = true;
                //console.log(this.fields);
                var today = new Date();
                var test =  this.formatInputDate(today);
                //var test =  today;
                console.log(test);           
                test =  this.formatInputDate(this.fields.tgl);
                console.log(test);           
                this.fields.tgl=test;
                const params = { 
                    tgl: this.fields.tgl,
                    page: 1,
                    limit: 50,
                };
                try {
                    var response = await this.apiGets(`atk_out/lastnumber/`, { params });
                    if(response.status == 200) {
                        var newFields = { ...this.fields };
                        newFields.notrans = response.data.no;
                        this.fields = newFields;
                        console.log('hasil last number');
                        console.log(this.fields);
                    }
                    
                } catch (error) {
                    // defaultErrors(error.response.data.errors)
                }
                
                this.isLoading = false;
            },
            async showGdg(index) {
                //this.$refs.listGdg.show();                
                //console.log(this.$refs.listGdg);
                //this.$refs.listGdg.show();
                this.baris=index;
                await this.getGudang();
                //this.$refs.listGdg[this.baris][0].show();
                this.$refs['listGdg'+index][0].show();
                console.log(this.$refs['listGdg'+index][0]);
                //this.$refs['listGdg'+index].show();
                //this.$refs['listGdg'].open();
                //this.$refs['modal-1'].show();
            },
            async showItem(index) {
                this.baris=index;
                await this.getItem();
                this.$refs['listItem'+index][0].show();
                console.log(this.$refs['listItem'+index][0]);              
            },
             simpleItems() {
                return this.detail.map((item) => {
                    return {
                    Acronym: item.Acronym.join(", "),
                    InterventionName: item.InterventionName.join(", "),
                    Condition: item.Condition.join(", "),
                    Phase: item.Phase.join(", "),
                    LastKnownStatus: item.LastKnownStatus.join(", "),
                    ResultsFirstPostDate: item.ResultsFirstPostDate.join(", "),
                    LastUpdatePostDate: item.LastUpdatePostDate.join(", "),
                    };
                })  
                },
            async getGudang() {
                this.isLoading = true;
                //console.log('gudang');
                const params = { 
                    page: 's',
                    limit: 10,
                };
                var response = await this.apiGets(`gudangs`, { params });
                if(response.status == 200) {
                    //this.lists = { ...response.data.data };
                    //this.gdgList = { ...response.data.data };  
                    var gdgList = { ...response.data.data };
                    //console.log(gdgList);
                    //var obj = {"1":5,"2":7,"3":0,"4":0,"5":0,"6":0,"7":0,"8":0,"9":0,"10":0,"11":0,"12":0}
                    //var result = Object.keys(obj).map((key) => [key, obj[key]]);
                    var result1 = Object.keys(gdgList).map((key) => (gdgList[key]));
                    //console.log(result1);
                    /* var result = Object.values(gdgList).map((key) => [key]);
                    console.log(result1);
                    console.log(result);
                    console.log(this.tes); */
                    this.gdgList=result1;
     
                }

                this.isLoading = false;
            },
            async getItem() {
                this.isLoading = true;
                //console.log('gudang');
                const params = { 
                    page: 's',
                    limit: 10,
                };
                var response = await this.apiGets(`items`, { params });
                if(response.status == 200) {
                    var itemList = { ...response.data.data };
                    var result = Object.keys(itemList).map((key) => (itemList[key]));
                    this.itemList=result;
     
                }
                this.isLoading = false;
            },
                      
             showSuccessToast() {
                        this.$swal({
                        title: 'What is your Name?',
                        input: 'text',
                        border: '1px solid #F0E1A1',
 
                        backdrop: "rgba(43, 165, 137, 0.45)",
    
                        });
                     
 
                 }
        }
    }
</script>